import React, { useEffect, useState } from 'react'
import HeaderSearch from '../community/headerSearch'
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import getNewsletter from '../../services/getNewsletter';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import CommunityFooter from '../community/footer';
import EmptyState from '../../pages/empty-state';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

let params = [];
const AnalyticsPosts = ({showDetailedAnalytics}) => {
    const { slug } = useSelector(state => state?.slugReducer);
    const [isDesc, setIsDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [allPosts, setallPosts] = useState([]);
    const pro = useSelector(state => state?.proReducer);
    const history = useHistory();
    const planType = useSelector(state => state?.plantypeReducer);
    const [filter, setFilter] = useState({
        orderBy: '',
        orderType: 'desc',
        page: 1,
        count: localStorage?.posts_count ?? 10
    });
    const filterToggler = (value) => {
        setFilter({
            ...filter,
            orderBy: value,
            orderType: isDesc ? 'asc' : 'desc',
        });
        setIsDesc(!isDesc); // Toggle between ascending and descending
    };
    useEffect(() => {
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key))
                    if (filter[key]) params.push(`${[key]}=${filter[key]}`)

            };
            if (filter?.page) callPosts()
        }
    }, [slug, filter, pro, planType]);
    const callPosts = () => {
        setIsLoading(true)
        getNewsletter(slug, params?.join("&"), "community/post").then((response) => {
            setallPosts(response?.paginated);
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            console.log('err', err);
        })
    }
    const editorJsText = (values, post) => {
        if (values) {
          values = JSON.parse(values);
          return values.map((entry) => {
            const { text } = entry;
            if (text && text.data && text.data.text) {
              return text.data.text; // Extract `data.text` from `text`
            }
            return ''; // Return empty string if data.text is not present
          });
        }
        return []; // Return empty array if values are undefined or null
      };
      const sendToPostsPage = () => {
        history.push("/posts")
      }
      console.log("allPosts",allPosts)
    return (
        <>
        {!allPosts?.data?.length > 0 && (filter?.filter == 'All' || !filter?.filter) ?
            <div class="section-main sec-main-u p-0 position-relative">
                <EmptyState from={"posts"} firstText={'*Cricket Noises*'} secondText={"Start writing blog posts and get your thoughts out into the world."} buttonText={"Create Post"} clickedFunction={sendToPostsPage}/>
            </div>
        :
        <div class="section-main d-flex flex-column justify-content-between bg-transparent gap-3" style={{height:"90%"}}>
            <div className='d-flex flex-column'>
                <div className='d-flex gap-2 align-items-center justify-content-start mt-20px'>
                    <div>
                        <HeaderSearch filter={filter} setfilter={setFilter} name="Posts" />
                    </div>
                    <button className={`posts-analytics-order ${isDesc ? 'desc' : ''}`} onClick={() => filterToggler("date_sent")}>
                        Publish Time
                        <img src={chevron_down} alt="" />
                    </button>
                </div>
                <div className='posts-analytics-section'>
                    {isLoading? (
                        Array(4).fill(null).map((width, index) => (
                            <div key={index} className='posts-analytics-post' style={{background: `linear-gradient(180deg, #f9f3ff 0%, #A34FE4 100%)`}}>
                                <div>
                                    <Skeleton width={"45%"} height={116}
                                        className="align-middle w-100"
                                        baseColor="rgba(163, 79, 228, 0.1)"
                                        highlightColor="#A34FE4"
                                        borderRadius="0.5rem"
                                    />
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <Skeleton width={150} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={16} borderRadius="0.5rem" />
                                    <Skeleton width={200} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={19} borderRadius="0.5rem" />
                                </div>
                            </div>
                        ))
                    ) : (
                        allPosts?.data?.map((post, index) => (
                            <div key={index} onClick={() => showDetailedAnalytics(post?.id, post?.subject, null ,post?.scheduled_at)} className='posts-analytics-post' style={{background: `linear-gradient(180deg, #f9f3ff 0%, ${post?.image_color} 100%)`,color:post?.text_color}}>
                                <div>
                                    <img src={post?.cover_image || 'https://images.zaap.ai/thumbnail/post-default0000000000+(1).png'} />
                                </div>
                                <div className='d-flex flex-column gap-1' >
                                    <div className='post-heading'>{post?.subject}</div>
                                    {post?.template_json && (
                                        <div className='post-description'>
                                        {editorJsText(post?.template_json, post).map((value, i) => (
                                            <div 
                                            className='post-description'
                                            key={i} 
                                            dangerouslySetInnerHTML={{ __html: value + '.' }} // Use `__html` correctly
                                            ></div>
                                        ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div class="section-footer d-flex align-items-center justify-content-between">
                <CommunityFooter from={"posts"} loading={isLoading} filter={filter} setfilter={setFilter} total={allPosts?.total} last_page={allPosts?.last_page} current_page={allPosts?.current_page}/>
            </div>
        </div>
        }
        </>
    )
}

export default AnalyticsPosts