import React from 'react'
import alertIcon from "../../assets/img/alert-circle.svg";
import { setDateFormatWithSuffix } from '../../utils/setDateFormat';
import Skeleton from 'react-loading-skeleton';
const AnalyticsHeader = ({pageData, toggleTab, tab, screenWidth,goBack, loading}) => {
    const pageName = window.location.pathname.split("/").pop();

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="analytics-session-warning">
                    <img src={alertIcon} alt="" width={20} height={20} />
                    <span>
                        Session analytics are not available on mobile devices. Please use a desktop to access them.
                    </span>
                </div>
                <div>
                    <div className='d-flex align-items-center gap-2 flex-wrap'>
                        {
                            pageData?.id &&
                            <svg className='cursor-pointer' onClick={() => goBack()} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path d="M14 7.5H1M1 7.5L7.5 14M1 7.5L7.5 1" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                        <div className="mb-0 page-heading text-capitalize">{pageData?.pageHeading}</div>
                        {
                            pageData?.id &&
                            <div className='published-at'>
                                <span className='small-box' style={{backgroundColor: "#0CBD5B"}}></span>
                                <span>
                                    Published on {setDateFormatWithSuffix(pageData?.created_at)}
                                </span>
                            </div>
                        }
                    </div>
                    <div className="page-description mt-10px">{pageData?.pageDesc}</div>
                </div>
                {loading ? 
                    (
                        <div>
                            <Skeleton width={211} height={36} baseColor='#FAFAFA' highlightColor='#EEEDEE' borderRadius="0.5rem"/>
                        </div>
                    )
                    :
                    (
                    screenWidth == false ?
                        <div class="toggle-input-checkbox analytics-toggle">
                            <input type="radio" id="text_align_left" name="planType" onChange={toggleTab} checked={tab == "overview"} style={{ display: "none" }} value={"overview"} />
                            <label for="text_align_left" > <span > Overview</span></label>
                            <input type="radio" id="text_align_right" name="planType" onChange={toggleTab} checked={tab == "sessions"} style={{ display: "none" }} value={"sessions"} />
                            <label for="text_align_right" > {pageName == 'page' ? 'Sessions':'Detailed Analytics'} </label>
                        </div>
                        :
                        null
                    )
                }
            </div>
  )
}

export default AnalyticsHeader